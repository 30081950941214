<template lang="pug">
  div
    CCard
      CCardBody
        CRow
          CCol(sm=5)
            h4(class="card-title mb-0") Склад

        vuecrud(selectedtable = "wb_stocks", :columnsupdated="columnsupdated", :opts="opts", style="margin-top:15px")

</template>

<script>

export default {
  name: 'Partner_Incomes',
  data: function () {
    return {
      show: true,
      isCollapsed: true,

      opts: {
        canAdd: false,
        canEdit: false,
        canDelete: false,
        where: {
        }
      }

    }
  },
  methods: {
    columnsupdated: function (cols) {

      cols.delete('id');
      cols.delete('partner');

      cols.set('lastChangeDate', {label: "Обновлено", hasFilter: false, });
      cols.set('nmId', {label: "ID", hasFilter: false, });
      cols.set('supplierArticle', {label: "Ваш артикул", hasFilter: false, });
      cols.set('techSize', {label: "Размер", hasFilter: false, });
      cols.set('barcode', {label: "Баркод", hasFilter: false, });
      cols.set('quantity', {label: "Остатки", hasFilter: false, });
      cols.set('isSupply', {label: "Договор поставки", hasFilter: false, });
      cols.set('isRealization', {label: "Договор реализации", hasFilter: false, });
      cols.set('quantityFull', {label: "Остатки (все)", hasFilter: false, });
      cols.set('quantityNotInOrders', {label: "Остатки (не в заказе)", hasFilter: false, });
      cols.set('warehouseName', {label: "Склад", hasFilter: false, });
      cols.set('inWayToClient', {label: "На пути к клиенту", hasFilter: false, });
      cols.set('inWayFromClient', {label: "На пути от клиента", hasFilter: false, });
      cols.set('subject', {label: "Предмет", hasFilter: false, });
      cols.set('category', {label: "Категория", hasFilter: false, });
      cols.set('daysOnSite', {label: "Дней на сайте", hasFilter: false, });
      cols.set('brand', {label: "Бренд", hasFilter: false, });
      cols.set('SCCode', {label: "Код контракта", hasFilter: false, });
      cols.set('Price', {label: "Цена", hasFilter: false, });
      cols.set('Discount', {label: "Скидка", hasFilter: false, });

      return cols;

    },
  }
}
</script>
